import React from 'react'
import '../styles/interview-current.css'

import { FaInstagram,FaYoutube,FaSpotify,FaBandcamp,FaSoundcloud,FaImdb,FaWikipediaW,FaLongArrowAltLeft } from 'react-icons/fa';
import { Link,useParams} from 'react-router-dom';
import ScrollToTop from '../components/ScrollToTop';
// we import the animation file to use it via variants and motion to make motion items for the page transition
import { PageAnimation,HeaderAnimationFade } from "../animations/PageAnimation.js";
import { motion } from "framer-motion";
import interviewsData from '../utils.js';


function Interview() {
    const { id } = useParams();
    
    // optional way without filter and map
    // const selectedInterview = interviewsData.find((selected) => selected.id == id);

return (
<>
{interviewsData
.filter(item=>item.id===parseInt(id))
.map((selectedInterview,index)=>{
    return(
    <motion.article key={index} variants={PageAnimation} initial="hidden" animate="show" exit="exit" className='interview-current'>
        <motion.h1  variants={HeaderAnimationFade} initial="hidden" animate="show">{selectedInterview.name}</motion.h1>
        <motion.h4  variants={HeaderAnimationFade} initial="hidden" animate="show">{selectedInterview.job}</motion.h4>

        <div className="inteview-info">
               <motion.img  variants={HeaderAnimationFade} initial="hidden" animate="show" src={`../images/interviews/${selectedInterview.img}`} alt={`{${selectedInterview.name}}photo`} />

          <motion.div  variants={HeaderAnimationFade} initial="hidden" animate="show" className="interview-info_links">
            <a href={`${selectedInterview.link1}`} target="_blank" rel="noopener noreferrer">
                {selectedInterview.social1==="FaInstagram" ? <FaInstagram/> :null}
                {selectedInterview.social1==="FaYoutube" ? <FaYoutube/> :null}
                {selectedInterview.social1==="FaSpotify" ? <FaSpotify/> :null}
                {selectedInterview.social1==="FaBandcamp" ? <FaBandcamp/> :null}
                {selectedInterview.social1==="FaSoundcloud" ? <FaSoundcloud/> :null}
                {selectedInterview.social1==="FaImdb" ? <FaImdb/> :null}
                {selectedInterview.social1==="FaWikipediaW" ? <FaWikipediaW/> :null}
            </a>
            <a href={`${selectedInterview.link2}`} target="_blank" rel="noopener noreferrer">
                {selectedInterview.social2==="FaInstagram" ? <FaInstagram/> :null}
                {selectedInterview.social2==="FaYoutube" ? <FaYoutube/> :null}
                {selectedInterview.social2==="FaSpotify" ? <FaSpotify/> :null}
                {selectedInterview.social2==="FaBandcamp" ? <FaBandcamp/> :null}
                {selectedInterview.social2==="FaSoundcloud" ? <FaSoundcloud/> :null}
                {selectedInterview.social2==="FaImdb" ? <FaImdb/> :null}
                {selectedInterview.social2==="FaWikipediaW" ? <FaWikipediaW/> :null}
            </a>
          </motion.div>

          </div>
            <h2>{selectedInterview.question1}</h2>
            <h3>{selectedInterview.answer1}</h3>
            <h2>{selectedInterview.question2}</h2>
            <h3>{selectedInterview.answer2}</h3>
            <h2>{selectedInterview.question3}</h2>
            <h3>{selectedInterview.answer3}</h3>
            {selectedInterview.answer31 ? <h3>{selectedInterview.answer31}</h3> :null}
            {selectedInterview.question4 ? <h2>{selectedInterview.question4}</h2> :null}
            {selectedInterview.answer4 ? <h3>{selectedInterview.answer4}</h3> : null}
            {selectedInterview.question5 ? <h2>{selectedInterview.question5}</h2> :null}
            {selectedInterview.answer5 ? <h3>{selectedInterview.answer5}</h3> : null}
            {selectedInterview.question6 ? <h2>{selectedInterview.question6}</h2> :null}
            {selectedInterview.answer6 ? <h3>{selectedInterview.answer6}</h3> : null}
            {selectedInterview.question7 ? <h2>{selectedInterview.question7}</h2> :null}
            {selectedInterview.answer7 ? <h3>{selectedInterview.answer7}</h3> : null}
            {selectedInterview.question8 ? <h2>{selectedInterview.question8}</h2> :null}
            {selectedInterview.answer8 ? <h3>{selectedInterview.answer8}</h3> : null}
            {selectedInterview.question9 ? <h2>{selectedInterview.question9}</h2> :null}
            {selectedInterview.answer9 ? <h3>{selectedInterview.answer9}</h3> : null}
            <Link className='back-link' to="/interviews"><FaLongArrowAltLeft/></Link>       
    </motion.article>
    
)}
)}
    
    <ScrollToTop/>
        
</>
)}

export default Interview
