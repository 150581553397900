export const PageAnimation={
    hidden:{
        opacity:0,
        y:50,
    },

    show:{
        opacity:1,
        y:0,
        transition:{
            duration:.8,
            when:'beforeChildren',
            staggerChildren:.25
        },
    },

    exit:{
        opacity:0,
        y:50,
        transition:{
            duration:.5
        },
    }

};
export const PageAnimationFade={
    hidden:{
        opacity:0
    },

    show:{
        opacity:1,
        transition:{
            duration:.6,
        },
    },

    exit:{
        opacity:0,
        transition:{
            duration:.4
        },
    }

};
export const staggerAnimation={
    hidden:{
        opacity:0
    },

    show:{
        opacity:1,
        transition:{
           staggerChildren:.25,
           duration:0.01,
           when:"beforeChildren"
        },
    },
    exit:{
        opacity:1,
        duration:0.5,
    }

};
export const staggerAnimation2={
    hidden:{
        opacity:0
    },

    show:{
        opacity:1,
        transition:{
           staggerChildren:.10,
           duration:0.01,
           when:"beforeChildren"
        },
    },
    exit:{
        opacity:1,
        duration:0.5,
    }

};

export const chapterAnimation={
    hidden:{
        opacity:0,
        x:-50
    },

    show:{
        opacity:1,
        x:0,
        transition: {duration: 0.25},
    },
    exit:{
        opacity:1
    }

};

export const yAnimation={
    hidden:{
        opacity:0,
        y:30
    },

    show:{
        opacity:1,
        y:0,
        transition: {duration: 0.25},
    },
    exit:{
        opacity:1
    }

};
export const xAnimation={
    hidden:{
        opacity:0,
        x:50,
        skewX:'0deg'
    },

    show:{
        x:0,
        opacity:1,
        transition: {duration: 0.25},
        skewX:'8deg'
    },
    exit:{
        opacity:1
    }
};


export const container = {
    hidden: { opacity: 1 },
    show: {
    
      opacity: 1,
      transition: {
        staggerChildren: .3,ease:"linear",
      }
    }
  }


export const HeaderAnimationFade={
    hidden:{
        opacity:0,
        y:-20,
    },

    show:{
        opacity:1,
        y:0,
        transition: {duration:.25},
    },
    exit:{
        opacity:0
    },
};

export const NavAnimationFade={
    hidden:{
        opacity:0,
    },

    show:{
        opacity:1,
        transition: {duration:.4,
        staggerChildren:0.25,
        when:'beforeChildren'
    },
    },
    exit:{
        opacity:0
    },
};

export const limitedYoyo={
    hidden:{
        opacity:0,
    },
    show:{
        opacity:1,
        transition: {
        duration:.35,
        yoyo:Infinity,
        type: "spring",
         bounce: 0.15
    },
}

};

export const simpleFade={
    hidden:{
        opacity:0
    },

    show:{
        opacity:1,
        transition:{
           duration:0.4,
        },
    },
    exit:{
        opacity:1,
        duration:0.5,
    }

};



