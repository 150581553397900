import React, { useRef, useEffect } from "react";
import "../styles/about-us.css";
//gsap and plugins import
import { gsap } from "gsap";
import { TextPlugin } from "gsap/TextPlugin";

const AboutUs = () => {
  /////GSAP ANIMATIONS
  // we declare Refs to animate in gsap
  const textRef = useRef();
  const imgChangeRef = useRef();
  const contentRef = useRef();
  const magazineRef = useRef();
  const backgroundRef = useRef();

  // _timelines
  const tl = useRef();
  const tl2 = useRef();
  const tl3 = useRef();

  // we make the TEXT CHANGER animations inside of useEffect
  useEffect(() => {
    gsap.registerPlugin(TextPlugin);
    gsap.fromTo(textRef.current, { opacity: 0, x: -50 }, { opacity: 1, x: 0 });
    tl.current = gsap.timeline({ repeat: -1, repeatDelay: 1, delay: 1.2 });
    tl.current.fromTo(
      textRef.current,
      2,
      { delay: 1, opacity: 0, text: "THE TRUE" },
      { opacity: 1, text: "INDEPENDENT" }
    );
    tl.current.fromTo(
      textRef.current,
      2,
      { opacity: 0 },
      { opacity: 1, text: "WORLDWIDE" },
      ">=1"
    );
    tl.current.fromTo(
      textRef.current,
      2,
      { opacity: 0 },
      { opacity: 1, text: "BLACK & WHITE" },
      ">=1"
    );
  }, []);

  // we make a IMAGE CHANGER function with all animations to use it in useEffect below
  function imgAutoChanger() {
    gsap.fromTo(
      imgChangeRef.current,
      { opacity: 0, y: 30 },
      { opacity: 1, y: 0, delay: 0.9 }
    );
    tl2.current = gsap.timeline({ repeat: -1, delay: 1.7 });
    tl2.current.set(
      imgChangeRef.current,
      { attr: { src: "images/vinyl.svg" }, opacity: 1 },
      ">"
    );
    tl2.current.fromTo(
      imgChangeRef.current,
      2,
      { opacity: 1 },
      { opacity: 0 },
      ">"
    );
    tl2.current.set(imgChangeRef.current, {
      attr: { src: "images/movie.svg" },
    });
    tl2.current.fromTo(
      imgChangeRef.current,
      2,
      { opacity: 1 },
      { opacity: 0 },
      ">"
    );
    tl2.current.set(imgChangeRef.current, {
      attr: { src: "images/music.svg" },
      opacity: 0,
    });
    tl2.current.fromTo(imgChangeRef.current, 2, { opacity: 0 }, { opacity: 1 });
    tl2.current.set(imgChangeRef.current, {
      attr: { src: "images/games.svg" },
      opacity: 0,
    });
    tl2.current.fromTo(imgChangeRef.current, 2, { opacity: 0 }, { opacity: 1 });
  }
  // we make a STAGGER function with all animations to use it in useEffect below
  function aboutStagger() {
    tl3.current = gsap.timeline();
    tl3.current.fromTo(
      contentRef.current,
      { y: 10, opacity: 0 },
      { y: 0, opacity: 1, duration: 0.3, delay: 0.5 }
    );
    tl3.current.fromTo(
      magazineRef.current,
      { opacity: 0, x: 20 },
      { opacity: 1, x: 0, duration: 0.5 },
      "<=.3"
    );
    tl3.current.fromTo(
      backgroundRef.current,
      { opacity: 0, x: 60 },
      { opacity: 1, x: 0, duration: 0.5 },
      ">=-.5"
    );
  }
  ///GSAP ANIMATIONS END//
  // we run the GSAP functions in useEffect
  useEffect(() => {
    aboutStagger();
    imgAutoChanger();
  }, []);

  return (
    <div className="about-us" id="aboutUs">
      <div className="about-us_container">
        <img
          ref={backgroundRef}
          className="background-photo"
          src="images/background.png"
          alt="background"
        />
        <span ref={textRef}>THE TRUE</span>
        <div ref={contentRef} className="about-us_bio">
          <h4>
            Passcode magazine is an independent limited printed art magazine
            released in June 2020.
          </h4>
          <div className="line"></div>
          <div className="p-container">
            <p>
              In Passcode magazine you can find interviews and articles from every
              kind of art including:
              
            </p>
            <p>
              Music,Cinema,Gaming,Tattoo art,Graffiti,Comics and other urban
              types of art culture in a
            </p>
            <p>
              black and white limited printed anthem of arts,with only purpose
              the true love in arts.
            </p>

            <p>
              In our website you can find exclusive material,that is not included
              in our limited hard copies.
            </p>
          </div>
          <h5>
            Created by Rania Triantafyllou and Jimmy Empne in early 2020.
          </h5>
        </div>
        <div className="about-us_change">
          <img src="images/games.svg" ref={imgChangeRef} alt="games etc " />
          <span ref={magazineRef}>magazine</span>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
