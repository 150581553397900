import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../styles/nav.css";
import { FaBars } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { motion } from "framer-motion";
import { PageAnimationFade } from "../animations/PageAnimation.js";
import MenuNav from "./MenuNav";
import { onClickScrollFindUs, onClickScrollOurWork } from "./ScrollOnClick";

const Nav = () => {
  //state for toggling hamburger menu
  const [toggle, setToggle] = useState(true);
  //we send this handler via props to <MenuNav/>
  const toggleHandler = () => {
    setToggle(true);
  };
  return (
    <motion.nav
      variants={PageAnimationFade}
      initial="hidden"
      animate="show"
      exit="exit"
      className="nav"
    >
      <div className="nav-container">
        <img src="images/PasscodeLogo.png" alt="logo" />

        <div className="nav-menu">

          <Link className="link-style" to="/interviews">
            <span>+</span> Interviews
          </Link>
          <Link
            className="link-style"
            onClick={() => {
              onClickScrollFindUs();
            }}
            to="/"
          >
            Find us
          </Link>
        </div>
        {toggle ? (
          <button
            onClick={() => {
              setToggle(false);
            }}
            className="toggle-menu"
          >
            <FaBars />
          </button>
        ) : (
          <button
            onClick={() => {
              setToggle(true);
            }}
            className="toggle-menu"
          >
            <IoMdClose />
          </button>
        )}
        {!toggle && <MenuNav toggle={toggleHandler} />}
      </div>
    </motion.nav>
  );
};

export default Nav;
