import React from "react";
import "../styles/not-found.css"
//router
import { Link } from "react-router-dom";
//icons
import { FaHome } from "react-icons/fa";
//this is a 404 errow page

const NotFound = () => {
  return (
    <div className="error-page">
      <h1>404</h1>
      <h2>ERROR</h2>
      <h4>Page doesn't exist.</h4>
      <span>
        <h3>Back to home page</h3>
        <Link to={"/"}>
          <FaHome className="back-to" />
        </Link>
      </span>
    </div>
  );
};

export default NotFound;