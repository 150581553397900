import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";
//collection of on scroll animations to import them to components
export const ScrollAnimations = () => {
  const controls = useAnimation();
  const [element, view] = useInView({ threshold: 0.25, triggerOnce: true });

  if (view) {
    controls.start("show");
  } else {
    controls.start("hidden");
  }
  return [element, controls];
};

export const ScrollAnimations2 = () => {
  const controls2 = useAnimation();
  const [element2, view] = useInView({ threshold: 0.25, triggerOnce: true });

  if (view) {
    controls2.start("show");
  } else {
    controls2.start("hidden");
  }
  return [element2, controls2];
};
export const ScrollAnimations3 = () => {
  const controls3 = useAnimation();
  const [element3, view] = useInView({ threshold: 0.25, triggerOnce: true });

  if (view) {
    controls3.start("show");
  } else {
    controls3.start("hidden");
  }
  return [element3, controls3];
};
export const ScrollAnimations4 = () => {
  const controls4 = useAnimation();
  const [element4, view] = useInView({ threshold: 0.25, triggerOnce: true });

  if (view) {
    controls4.start("show");
  } else {
    controls4.start("hidden");
  }
  return [element4, controls4];
};
//for Find us section component
export const ScrollAnimationsFind = () => {
  const controls = useAnimation();
  const [element, view] = useInView({ threshold: 0.3, triggerOnce: true });

  if (view) {
    controls.start("show");
  } else {
    controls.start("hidden");
  }
  return [element, controls];
};
export const ScrollAnimationsFind2 = () => {
  const controls2 = useAnimation();
  const [element2, view] = useInView({ threshold: 0.3, triggerOnce: true });

  if (view) {
    controls2.start("show");
  } else {
    controls2.start("hidden");
  }
  return [element2, controls2];
};
export const ScrollAnimationsFind3 = () => {
  const controls3 = useAnimation();
  const [element3, view] = useInView({ threshold: 0.3, triggerOnce: true });

  if (view) {
    controls3.start("show");
  } else {
    controls3.start("hidden");
  }
  return [element3, controls3];
};

export const ScrollAnimationsFind4 = () => {
  const controls4 = useAnimation();
  const [element4, view] = useInView({ threshold: 0.2 });

  if (view) {
    controls4.start("show");
  } else {
    controls4.start("hidden");
  }
  return [element4, controls4];
};
export const ScrollAnimationsFind5 = () => {
  const controls5 = useAnimation();
  const [element5, view] = useInView({ threshold: 0.2 });

  if (view) {
    controls5.start("show");
  } else {
    controls5.start("hidden");
  }
  return [element5, controls5];
};
