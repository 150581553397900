import React from "react";
//components and pages
import Nav from "./components/Nav";
import NavInterviews from "./components/NavInterviews";
import Main from "./pages/Main";
import Footer from "./components/Footer";
import Interviews from "./pages/Interviews";
import Interview from "./pages/Interview";
import NotFound from "./pages/NotFound";
import CustomCursor from "./components/CustomCursor";
//css general styles
import "./styles/style.css";
//react router
import { Switch, Route, useLocation } from "react-router-dom";
//scroll to top when path changes or page reload
import ScrollToTop from "./components/ScrollToTop";
//animate presence from framer-motion for page transitions
import { AnimatePresence } from "framer-motion";

function App() {
  //we use useLocation to take the key to use it for page transitions
  const location = useLocation();
  return (
    <>
      <CustomCursor />
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route path="/" exact>
            <Nav />
            <Main />
            <Footer />
            <ScrollToTop />
          </Route>
          <Route path="/interviews" exact>
            <NavInterviews />
            <Interviews />
            <Footer />
          </Route>
          <Route path="/interviews/:id" exact>
            <NavInterviews />
            <Interview />
            <Footer />
          </Route>
          <Route path="*">
            <NotFound/>
          </Route>
        </Switch>
      </AnimatePresence>
    </>
  );
}

export default App;
