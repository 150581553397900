import React from "react";
import { Link } from "react-router-dom";
import "../styles/menu-nav.css";
import { NavAnimationFade } from "../animations/PageAnimation.js";
import { motion } from "framer-motion";
//we import scroll on click to run them below
import { onClickScrollFindUs, onClickScrollOurWork } from "./ScrollOnClick";

//we take props from Nav.js component to open and close menu on click of links below
function MenuNav(props) {
  return (
    <motion.div
      variants={NavAnimationFade}
      initial="hidden"
      animate="show"
      exit="exit"
      className="mobile-menu-container"
    >
      {/* onClick scroll to section and via props close the menu */}
      <Link
        className="mobile-item"
        onClick={() => {
          onClickScrollFindUs();
          props.toggle();
        }}
        to="/"
      >
        <span>01.</span>Find us
      </Link>
      <Link className="mobile-item" to="/interviews">
        <span>+</span>Interviews
      </Link>
    </motion.div>
  );
}
export default MenuNav;
