import React,{useEffect,useRef} from 'react'
import '../styles/style.css'
import '../styles/menu-nav.css'

function CustomCursor() {
    const customRef=useRef(null)
    useEffect(() => {
        document.addEventListener('mousemove',(event)=>{
            const {clientX,clientY}=event;
            const mouseX=clientX-customRef.current.clientWidth/2;
            const mouseY=clientY-customRef.current.clientHeight/2;
            customRef.current.style.transform=`translate3d(${mouseX}px,${mouseY}px,0)`;
        })
    }, []);
    return (
        <div ref={customRef} className='custom-cursor'>   
        </div>
    )
}

export default CustomCursor
