import React from 'react'
import AboutUs from '../components/AboutUs';
import OurWork from '../components/OurWork';
import FindUs from '../components/FindUs';
import ScrollToTop from '../components/ScrollToTop';
// we import the animation file to use it via variants and motion to make motion items for the page transition
import { PageAnimationFade } from "../animations/PageAnimation.js";
import { motion } from "framer-motion";

function Main() {
    return (
        <motion.main variants={PageAnimationFade} initial="hidden" animate="show" exit="exit">
          <AboutUs/>
          <OurWork/>
          <FindUs/>
          <ScrollToTop/>
        </motion.main>
        
    )
}

export default Main
