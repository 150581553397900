import React from "react";
import "../styles/our-work.css";
import "../styles/style.css";
//import react-player for videos
import ReactPlayer from "react-player/youtube";
//import these for animations
import { motion } from "framer-motion";
import {
  staggerAnimation,
  chapterAnimation,
  yAnimation,
} from "../animations/PageAnimation.js";
//import on scroll animations component custom hook
import {
  ScrollAnimations,
  ScrollAnimations2,
  ScrollAnimations3,
  ScrollAnimations4,
} from "./ScrollAnimations";

const OurWork = () => {
  //on scroll animations deconstruct
  const [element, controls] = ScrollAnimations();
  const [element2, controls2] = ScrollAnimations2();
  const [element3, controls3] = ScrollAnimations3();
  const [element4, controls4] = ScrollAnimations4();
  //for custom cursor fix because of bug when hovering the ReactPlayer component
  //onMouseEnter & Leave will fix this
  const wrapper = document.querySelector(".custom-cursor");
  //state for pop up component that we have imported

  return (
    <section className="our-work" id="ourWork">
      <motion.div className="our-work_container">
        <motion.div className="our-work_container-row">
          <motion.div
            ref={element}
            variants={staggerAnimation}
            initial="hidden"
            animate={controls}
            className="our-work_container-item"
          >
            <motion.h2 variants={chapterAnimation}>#1</motion.h2>
            <motion.img
              variants={chapterAnimation}
              src="images/chapter1.png"
              alt="chapter 1"
            />
            <motion.h4 variants={chapterAnimation}>#1 preview</motion.h4>
            <motion.div
              className="player_wrapper"
              variants={chapterAnimation}
              onMouseEnter={() => {
                wrapper.style.display = "none";
              }}
              onMouseLeave={() => {
                wrapper.style.display = "flex";
              }}
            >
              <ReactPlayer
                loop
                controls
                url="http://www.youtube-nocookie.com/embed/1go0y7SMoYY"
                width="100%"
                height="100%"
              />
            </motion.div>
            <motion.div
              variants={yAnimation}
              className="our-work_gap"
            ></motion.div>
          </motion.div>

          <motion.div
            ref={element2}
            variants={staggerAnimation}
            initial="hidden"
            animate={controls2}
            className="our-work_container-item"
          >
            <motion.h2 variants={chapterAnimation}>#2</motion.h2>
            <motion.img
              variants={chapterAnimation}
              src="images/chapter2.png"
              alt="chapter 2"
            />
            <motion.h4 variants={chapterAnimation}>#2 preview</motion.h4>
            <motion.div
              className="player_wrapper"
              variants={chapterAnimation}
              onMouseEnter={() => {
                wrapper.style.display = "none";
              }}
              onMouseLeave={() => {
                wrapper.style.display = "flex";
              }}
            >
              <ReactPlayer
                loop
                controls
                url="http://www.youtube-nocookie.com/embed/JLIv5xPbLyI"
                width="100%"
                height="100%"
              />
            </motion.div>
            <motion.div
              variants={yAnimation}
              className="our-work_gap"
            ></motion.div>
          </motion.div>
        </motion.div>
        <motion.div className="our-work_container-row">
          <motion.div
            ref={element3}
            variants={staggerAnimation}
            initial="hidden"
            animate={controls3}
            className="our-work_container-item"
          >
            <motion.h2 variants={chapterAnimation}>#3</motion.h2>
            <motion.img
              variants={chapterAnimation}
              src="images/chapter3.png"
              alt="chapter 3"
            />
            <motion.h4 variants={chapterAnimation}>#3 preview</motion.h4>
            <motion.div
              className="player_wrapper"
              variants={chapterAnimation}
              onMouseEnter={() => {
                wrapper.style.display = "none";
              }}
              onMouseLeave={() => {
                wrapper.style.display = "flex";
              }}
            >
              <ReactPlayer
                loop
                controls
                url="http://www.youtube-nocookie.com/embed/96gQVg5727M"
                width="100%"
                height="100%"
              />
            </motion.div>
            <motion.div
              variants={yAnimation}
              className="our-work_gap"
            ></motion.div>
          </motion.div>

          <motion.div
            ref={element4}
            variants={staggerAnimation}
            initial="hidden"
            animate={controls4}
            className="our-work_container-item"
          >
            <motion.h2 variants={chapterAnimation}>#4</motion.h2>
            <motion.img
              variants={chapterAnimation}
              src="images/chapter4.png"
              alt="chapter 4"
            />
            <motion.h4 variants={chapterAnimation}>#4 preview</motion.h4>
            <motion.div
              className="player_wrapper"
              variants={chapterAnimation}
              onMouseEnter={() => {
                wrapper.style.display = "none";
              }}
              onMouseLeave={() => {
                wrapper.style.display = "flex";
              }}
            >
              <ReactPlayer
                loop
                controls
                url="https://www.youtube.com/watch?v=L9my09yV6r8"
                width="100%"
                height="100%"
              />
            </motion.div>
          </motion.div>
        </motion.div>
      </motion.div>
    </section>
  );
};

export default OurWork;
