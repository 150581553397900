import React from "react";
import "../styles/find-us.css";
// import { gsap } from "gsap";
import { FaInstagram } from "react-icons/fa";
import { motion } from "framer-motion";
import {
  ScrollAnimationsFind,
  ScrollAnimationsFind2,
  ScrollAnimationsFind3,
  ScrollAnimationsFind4,
  ScrollAnimationsFind5,
} from "./ScrollAnimations";
import {
  staggerAnimation2,
  chapterAnimation,
  simpleFade,
  limitedYoyo,
  xAnimation,
} from "../animations/PageAnimation.js";

const FindUs = () => {
  // /////////////////////
  //on scroll animations deconstruct
  const [element, controls] = ScrollAnimationsFind();
  const [element2, controls2] = ScrollAnimationsFind2();
  const [element3, controls3] = ScrollAnimationsFind3();
  const [element4, controls4] = ScrollAnimationsFind4();
  const [element5, controls5] = ScrollAnimationsFind5();
  return (
    <section className="find-us" id="findUs">
      <div className="find-us_container">
        <motion.h2
          ref={element5}
          variants={simpleFade}
          initial="hidden"
          animate={controls5}
        >
          GET YOUR{" "}
          <motion.span variants={limitedYoyo} initial="hidden" animate="show">
            LIMITED
          </motion.span>{" "}
          PRINT COPY NOW !
        </motion.h2>
        {/* we set these below to display them later with media queries */}
        <motion.div
          className="animation-flex_container"
          ref={element4}
          variants={staggerAnimation2}
          initial="hidden"
          animate={controls4}
        >
          <motion.h1 variants={xAnimation}>GET YOUR </motion.h1>
          <motion.h3 variants={limitedYoyo}>LIMITED PRINT</motion.h3>
          <motion.h1 variants={xAnimation} className="margin-bottom">
            NOW!
          </motion.h1>
        </motion.div>
        {/* //////////*/}
        <motion.div
          ref={element}
          variants={staggerAnimation2}
          initial="hidden"
          animate={controls}
          className="find-us-item"
        >
          <motion.h4>ON PHYSICAL SPOTS</motion.h4>
          <div className="find-us_spot-container">
            <motion.div variants={chapterAnimation} className="find-us-spot" onClick={()=>window.open('https://www.instagram.com/black_rose_tattoo/')}>
              <img src="images/blackrose.png" alt="spot1" />
            </motion.div>
            <motion.div variants={chapterAnimation} className="find-us-spot" onClick={()=>window.open('https://www.instagram.com/koureion123/')}>
              <img src="images/koyreion.png" alt="spot2" />
            </motion.div>
          </div>
        </motion.div>
        <motion.div
          ref={element2}
          variants={staggerAnimation2}
          initial="hidden"
          animate={controls2}
          className="find-us-item"
        >
          <motion.h4 variants={chapterAnimation}>
            FOLLOW US & SEND US DIRECT MESSAGE ON{" "}
          </motion.h4>
          <motion.a
            variants={chapterAnimation}
            href="https://www.instagram.com/passcodemagazine/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram />
          </motion.a>
        </motion.div>
        <motion.div
          ref={element3}
          variants={staggerAnimation2}
          initial="hidden"
          animate={controls3}
          className="find-us-item"
        >
        </motion.div>
      </div>
    </section>
  );
};

export default FindUs;
