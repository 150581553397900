//we do this to scroll on the items we want to scroll
export const onClickScrollOurWork=()=>{
    const ourWorkScroll = document.querySelector('#ourWork')
    ourWorkScroll.scrollIntoView({ behavior:'smooth', block:'start' })
}

export const onClickScrollFindUs=()=>{
    const findUsScroll = document.querySelector('#findUs') 
    findUsScroll.scrollIntoView({ behavior:'smooth', block:'start' })
}
