import React from 'react'
import { Link } from 'react-router-dom';
import '../styles/nav.css'
import '../styles/interview-current.css'
import { FaHome } from 'react-icons/fa';
import Logo from '../Logo.png'
import { PageAnimationFade } from "../animations/PageAnimation.js";
import { motion } from "framer-motion";


function NavInterviews() {
    return (
        <motion.nav variants={PageAnimationFade} initial="hidden" animate="show" exit="exit" className="nav-interviews">
        <div className="nav-container_interviews">
        <img src={Logo} alt="logo"/>
        <div className="nav-menu_interviews">
        <Link className='home-link' to="/"><FaHome/></Link>     
        </div>
        </div>
        
    </motion.nav>

    )
}

export default NavInterviews
