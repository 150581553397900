import React from 'react'
import '../styles/interviews.css'
import { Link } from 'react-router-dom';
import ScrollToTop from '../components/ScrollToTop';
// we import the animation file to use it via variants and motion to make motion items for the page transition
import { PageAnimation,HeaderAnimationFade } from "../animations/PageAnimation.js";
import { motion } from "framer-motion";
import interviewsData from '../utils.js';

function Interviews() {
  return (
  <motion.section variants={PageAnimation} initial="hidden" animate="show" exit="exit"  className='interviews'>
      <div className="interviews-container">
        <motion.h1 variants={HeaderAnimationFade} initial="hidden" animate="show"><span>+</span>INTERVIEWS</motion.h1>
        {interviewsData.map((item)=>{
          return(
          <motion.div variants={HeaderAnimationFade} initial="hidden" animate="show" className="interviews-item" key={item.id}>
          <Link className='interviewed' to={`/interviews/${item.id}`} key={item.id}>{item.name}</Link>
          <motion.h3 variants={HeaderAnimationFade} initial="hidden" animate="show">{item.job}</motion.h3>
          </motion.div>
         )
         })}
      </div>
      <ScrollToTop/>
  </motion.section>
        
    )
}

export default Interviews
