import {useEffect} from 'react'
import { useLocation } from 'react-router-dom'

function ScrollToTop() {
    const {pathname}=useLocation();
    
    useEffect(() => {
        window.onbeforeunload = function () {
            window.scroll({
                top:0,
                left:0
                })
          }
        return (null)
    }, [pathname])
    
    useEffect(() => {
        window.scroll({
            top:0,
            left:0
            })
            return(
                null
            )
    }, [pathname])
    return(null)
}

export default ScrollToTop
