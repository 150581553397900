import React from 'react';
import { FaInstagram,FaFacebook,FaRegArrowAltCircleUp } from 'react-icons/fa'
import '../styles/footer.css'
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import { PageAnimationFade } from "../animations/PageAnimation.js";
import Logo from '../Logo.png'


const Footer=()=>{
return(
<motion.footer variants={PageAnimationFade} initial="hidden" animate="show" exit="exit">
    <div className="footer-container">
        <div className="footer-container_item">
        <div className="footer-container_item-nav">
            <span className='link-style' onClick={()=>{
                window.scrollTo({
                top: 0,
                behavior: 'smooth'
                });
                }} ><FaRegArrowAltCircleUp/></span>
        </div>
        </div>

        <div className="footer-container_item">
            <Link to='/'><img src={Logo} alt="main logo"/></Link>
        </div>
                
        <div className="footer-container_item">
            <div className="footer-container_item-social">
                <h4>FOLLOW US ON:</h4>
                <a href="https://www.instagram.com/passcodemagazine/" target="_blank" rel="noopener noreferrer"><FaInstagram/></a>
                <a href="https://www.facebook.com/PasscodeMagazine/" target="_blank" rel="noopener noreferrer"><FaFacebook/></a>
            </div>
        </div>

    </div>
</motion.footer>
)
}

export default Footer;


